import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCodepen,
    faFreeCodeCamp,
    faGithub,
    faHackerrank,
    faLinkedin,
    faNpm
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faCodepen, faEnvelope, faFreeCodeCamp, faGithub, faHackerrank, faLinkedin, faNpm);
